import React, { useState, useEffect } from 'react'
import { Match } from '@reach/router'

import useAsyncEffect from 'utils/hooks/useAsyncEffect'
import DatabaseService from 'services/DatabaseService'
import ItemList from './ItemList'
import ItemEdit from './ItemEdit'

function InventoryList(props) {
  const [items, setItems] = useState(null)

  useAsyncEffect(async () => {
    const itemsSnap = await DatabaseService.getCurrentUserItems()
    setItems(
      itemsSnap.docs.map(docSnap => {
        return { id: docSnap.id, ...docSnap.data() }
      })
    )
  }, [])

  // still loading
  if (items === null) {
    return null
  }

  return <ItemList items={items} />
}

export default function InventoryTab(props) {
  const { item = null, location: { state = {} } = {} } = props

  const [itemData, setItemData] = useState(state)

  useEffect(() => {
    setItemData(state || {})
  }, [state])

  // TODO: Load item based on url?
  // useAsyncEffect(async()=>{
  //   if(typeof item === null){
  //     return
  //   }
  // }, [item])

  if (itemData.item) {
    return <ItemEdit location={props.location} />
  }

  return ((
    <InventoryList {...props} /> //: <ItemEdit location={location}/>
  ) /*: <ItemEdit location={location}/>*/)
}

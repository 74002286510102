import React from 'react'
import styled from '@emotion/styled'
import Link from 'gatsby-link'

import './ItemRentalList.scss'
import ItemRentalItem from './ItemRentalItem.js'

const ItemList = styled('ul')`
  list-style: none;
  margin: 1rem 0 0;
  padding: 20px 100px;

  @media (max-width: 900px) {
    padding: 20px 10px;
  }
`

const MODAL_A = 'modal_a'

export default class ItemRentalList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lending: this.props.lending,
    }
  }

  render() {
    let { items = [], lending } = this.props
    return (
      <ItemList>
        {items.length == 0 ? (
          lending ? (
            <div
              className="hint-text"
              style={{ marginTop: 15, textAlign: 'center' }}
            >
              You haven't lent out any items yet,{' '}
              <Link to="/submit">list an item.</Link>
            </div>
          ) : (
            <div
              className="hint-text"
              style={{ marginTop: 15, textAlign: 'center' }}
            >
              You haven't rented an item yet,{' '}
              <Link to="/search">find an item to rent.</Link>
            </div>
          )
        ) : (
          items.map(item => (
            <ItemRentalItem key={item.id} item={item} lending={lending} />
          ))
        )}
      </ItemList>
    )
  }
}

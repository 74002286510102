import React from 'react'
import styled from '@emotion/styled'
import Link from 'gatsby-link'

import './ItemList.scss'
import { button } from 'utils/styles'
import { getRoute } from 'utils/index'

const ListBtn = styled('button')`
  display: flex;
  flex: 1;
  ${button.purple};
  ${button.big};
`

export default ({ items }) => (
  <div className="flex-column">
    <div className="product-dashboard-grid">
      <div className="no-items-container">
        <div>
          <Link to="/submit">
            <ListBtn>
              {items.length == 0 ? 'List your first item' : 'List a new item'}
            </ListBtn>
          </Link>
        </div>
      </div>
      {items.map(item => (
        <div className="product-container" key={item.id}>
          <div className="product-item">
            <div className="img-container">
              <img
                src={item.img || item.picture || item.pictures[0]}
                className="cover-img"
              />
            </div>
            <div className="description">
              <div className="header">
                <h4 className="title">{item.name}</h4>
                <h5 className="price">
                  <b>${item.price_d / 100}</b> /day
                </h5>
              </div>
              <div className="footer">
                <div />
                <Link
                  to={getRoute('EDIT_ITEM', { id: item.id })}
                  state={{
                    item: {
                      id: item.id,
                      pictures: item.pictures,
                      name: item.name,
                      type: item.type,
                      description: item.description,
                      zipcode: item.zipcode,
                      price_d: item.price_d,
                    },
                  }}
                >
                  <button className="rent-btn">EDIT</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)

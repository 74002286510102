import React from 'react'
import styled from '@emotion/styled'

import './ProfileTab.scss'
import { button, colors } from 'utils/styles'
import Uploader from 'components/shared/Forms/Uploader'
import AuthService from 'services/AuthService'
import DatabaseService from 'services/DatabaseService'

const Label = styled('label')`
  color: ${colors.brand};
  display: block;
  font-size: 0.875rem;
  margin-top: 1rem;
  width: 100%;
`

const Avatar = styled('img')`
  border: 3px solid ${colors.brandBright};
  border-radius: 100px;
  box-sizing: border-box;
  display: block;
`

const DefaultAvatar = styled('svg')`
  border: 3px solid ${colors.brandBright};
  border-radius: 100px;
  color: #adb5bd;
  align-self: center;
  display: flex;
  justify-self: center;
`

const SubmitBtn = styled('a')`
  ${button.purple};
  ${button.big};
`

export default class ProfileTabBase extends React.Component {
  static defaultProps = {
    profile: {},
  }

  state = {
    user_img: this.props.profile.picture,
    user_firstName: this.props.profile.firstName,
    user_lastName: this.props.profile.lastName,
    user_email: this.props.profile.email,
    user_city: this.props.profile.city,
    user_phone: this.props.profile.phone,
    user_bio: this.props.profile.description,
    initFirebase: false,
    updateClicked: false,
    saved: true,
    saving: false,
  }

  onUpdate = async data => {
    try {
      if (data.picture) {
        await AuthService.auth.currentUser.updateProfile({
          photoURL: data.picture,
        })
      }
      await DatabaseService.updateCurrentUser(data)
    } catch (error) {
      return error
    }
  }

  _submit = async () => {
    this.setState({ saving: true })
    let data = {}
    data.firstName = this.state.user_firstName
    data.lastName = this.state.user_lastName
    data.picture = this.state.user_img
    data.description = this.state.user_bio
    data.city = this.state.user_city
    data.phone = this.state.user_phone

    const error = await this.onUpdate(data)
    if (error) {
      return this.setState({ saving: false, error })
    }
    this.setState({ saving: false, saved: true })
  }

  onChange = event => {
    this.setState({ saved: false, [event.target.name]: event.target.value })
  }

  render() {
    const {
      user_firstName,
      user_lastName,
      user_img,
      user_email,
      user_city,
      user_bio,
      user_phone,
      saved,
      saving,
      updateClicked,
    } = this.state

    return (
      <div className="dashboard-tab">
        <div className="dashboard-tab dashboard-tab-wrapper">
          <div className="dashboard-home-container">
            <div className="dashboard-home">
              <div className="dashboard-profile-container">
                <div className="dashboard-left">
                  <div className="dashboard-photo-container">
                    {user_img ? (
                      <Avatar src={user_img} className="dashboard-user-photo" />
                    ) : (
                      <DefaultAvatar
                        className="dashboard-user-photo"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        data-prefix="fas"
                        data-icon="user-circle"
                        role="img"
                        viewBox="0 0 496 512"
                        data-ember-extension="1"
                      >
                        <path
                          fill="currentColor"
                          d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
                        />
                      </DefaultAvatar>
                    )}
                  </div>
                  <Uploader
                    name="file"
                    onUploadComplete={info =>
                      this.setState({ user_img: info.cdnUrl, saved: false })
                    }
                  />
                  <div className="profile-fixed-details">
                    <div className="profile-fixed-detail-item">
                      <div className="profile-fixed-detail-title">Rating:</div>
                      <div className="profile-fixed-detail-detail hint-text italic">
                        No reviews yet
                      </div>
                    </div>
                    <div className="profile-fixed-detail-item">
                      <div className="profile-fixed-detail-title">Email:</div>
                      <div className="profile-fixed-detail-detail hint-text italic">
                        {user_email}
                      </div>
                    </div>
                  </div>
                </div>
                <form className="profile-form">
                  <div className="profile-form-row">
                    <fieldset className="fieldset fieldset-with-label profile-field">
                      <label htmlFor="user_firstName">First Name</label>
                      <input
                        type="text"
                        name="user_firstName"
                        id="user_firstName"
                        value={user_firstName}
                        maxLength="250"
                        onChange={this.onChange}
                      />
                    </fieldset>
                    <fieldset className="fieldset fieldset-with-label profile-field">
                      <label htmlFor="user_lastName">Last Name</label>
                      <input
                        type="text"
                        name="user_lastName"
                        id="user_lastName"
                        value={user_lastName}
                        maxLength="250"
                        onChange={this.onChange}
                      />
                    </fieldset>
                  </div>
                  <div className="profile-form-row">
                    <fieldset className="fieldset fieldset-with-label profile-field">
                      <label htmlFor="user_city">City</label>
                      <input
                        type="text"
                        name="user_city"
                        id="user_city"
                        value={user_city}
                        maxLength="250"
                        onChange={this.onChange}
                      />
                    </fieldset>
                    <fieldset className="fieldset fieldset-with-label profile-field">
                      <label htmlFor="user_phone">Phone Number</label>
                      <input
                        type="tel"
                        name="user_phone"
                        id="user_phone"
                        value={user_phone}
                        maxLength="250"
                        onChange={this.onChange}
                      />
                    </fieldset>
                  </div>
                  <div className="profile-form-row">
                    <fieldset className="fieldset fieldset-with-label profile-field">
                      <label htmlFor="user_bio">Bio</label>
                      <textarea
                        type="text"
                        name="user_bio"
                        className="text-input"
                        id="user_bio"
                        value={user_bio}
                        maxLength="450"
                        rows="3"
                        onChange={this.onChange}
                      />
                    </fieldset>
                  </div>
                  <div className="bottom-btn-container step-1">
                    <div>
                      <SubmitBtn
                        disabled={saved}
                        onClick={() => this._submit()}
                      >
                        {saving ? 'Saving...' : saved ? 'Saved' : 'Save'}
                      </SubmitBtn>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

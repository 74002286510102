import React from 'react'
import styled from '@emotion/styled'

import { colors } from 'utils/styles'

const Label = styled('span')`
  border-radius: 4px;
  color: ${colors.brand};
  padding: 6px 10px;
  background-color: ${colors.brandLight};
  font-weight: 600;
  text-transform: lowercase;
  font-size: 11px;
`

export const Tag = props => {
  let { item } = props
  const { approved, cancelled } = item
  const getLabel = () => {
    if (cancelled) {
      return 'Canceled'
    }
    if (typeof approved === 'undefined') {
      return 'Awaiting Approval'
    }
    if (approved === true) {
      return 'Approved'
    }
    if (approved === false) {
      return 'Rejected'
    }
  }
  return (
    <div style={{ marginTop: 10 }}>
      <Label>{getLabel()}</Label>
    </div>
  )
}

import React from 'react'
import { Match, Link } from '@reach/router'
import { observer } from 'mobx-react-lite'

import styles from './dashboard.module.scss'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/SEO/SEO'
import ProfileTab from 'components/DashboardTabs/ProfileTab/ProfileTab'
import UserStateStore from 'state/UserStateStore'
import TabNavigator from 'components/TabNavigator/TabNavigator'
import SettingsTab from 'components/DashboardTabs/SettingsTab/SettingsTab'
import InventoryTab from 'components/DashboardTabs/InventoryTab/InventoryTab'
import RentalsTab from 'components/DashboardTabs/RentalsTab/RentalsTab'

const DashboardPage = observer(props => {
  const { match, location, navigate } = props
  const profile = UserStateStore.user
  // console.log(match)
  if (!profile) {
    return null
  }
  const tabs = [
    {
      content: 'Profile',
    },
    {
      content: 'Rental History',
      route: 'rentals',
    },
    {
      content: 'Inventory',
      route: 'items',
    },
    {
      content: 'Settings',
      route: 'settings',
    },
  ]
  const activeTab = Math.max(
    0,
    tabs.findIndex(tab => {
      return match === null
        ? 0
        : tab.route && tab.route.toLowerCase() === match.tab.toLowerCase()
    })
  )
  return (
    <TabNavigator
      tabs={tabs}
      className={styles.container}
      tabContainerClass={styles.tabContainer}
      tabContainerProps={{
        style: { '--num_menu_items': tabs.length, '--index': activeTab },
      }}
      activeTabClass={styles.active}
      selected={activeTab}
      onSelectTab={tab => navigate(`/dashboard/${tabs[tab].route || ''}`)}
    >
      <ProfileTab match={match} profile={profile} />
      <RentalsTab profile={profile} />
      <InventoryTab location={location} match={match} />
      <SettingsTab match={match} profile={profile} />
    </TabNavigator>
  )
})

export default () => (
  <Match path={`/dashboard/:tab`}>
    {({ match, location, navigate }) => (
      <Layout location={location}>
        <SEO title="Dashboard" />
        <DashboardPage navigate={navigate} location={location} match={match} />
      </Layout>
    )}
  </Match>
)

import React from 'react'
import styled from '@emotion/styled'

import { button, colors, spacing, radius } from 'utils/styles'

const ActionBtn = styled('button')`
  text-transform: uppercase;
  ${button.default};
  ${button.small};
  padding: 10px 14px;
  margin-left: 5px;
  font-size: 12px;
`

export const MessageBtn = props => {
  if (!props.onClick) {
    return <div />
  }
  let { item, lending } = props

  return (
    <div>
      <ActionBtn {...props}>
        Message
        {lending ? ' Renter' : ' Lender'}
      </ActionBtn>
    </div>
  )
}

export const AlertBtn = props => {
  if (!props.onClick) {
    return <div />
  }

  let { item, alertClicked } = props
  return (
    <>
      {!alertClicked ? (
        <svg
          className="alert-icon"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          data-prefix="far"
          data-icon="exclamation-circle"
          role="img"
          viewBox="0 0 512 512"
          data-ember-extension="1"
          {...props}
        >
          <path
            fill="currentColor"
            d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 448c-110.532 0-200-89.431-200-200 0-110.495 89.472-200 200-200 110.491 0 200 89.471 200 200 0 110.53-89.431 200-200 200zm42-104c0 23.159-18.841 42-42 42s-42-18.841-42-42 18.841-42 42-42 42 18.841 42 42zm-81.37-211.401l6.8 136c.319 6.387 5.591 11.401 11.985 11.401h41.17c6.394 0 11.666-5.014 11.985-11.401l6.8-136c.343-6.854-5.122-12.599-11.985-12.599h-54.77c-6.863 0-12.328 5.745-11.985 12.599z"
          />
        </svg>
      ) : (
        <ActionBtn {...props}>Report Issue</ActionBtn>
      )}
    </>
  )
}

export const ChoiceBtn = props => {
  if (!props.onClick) {
    return <div />
  }
  let { item, lending } = props
  return (
    <>
      {lending && typeof item.approved === 'undefined' && !item.cancelled && (
        <ActionBtn {...props}>confirm/reject</ActionBtn>
      )}
    </>
  )
}

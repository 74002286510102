import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { colors, button, spacing, radius } from 'utils/styles'
import DatabaseService from 'services/DatabaseService'

const ActivateBtn = styled('button')`
  display: block;
  ${button.purple};
  ${button.small};
`

const CheckRow = styled('div')`
  display: flex;
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin: ${spacing.sm}px 0;
  position: relative;
`

const CheckText = styled('div')`
  margin-left: 6px;
`

const Label = styled('label')`
  display: block;
  width: 100%;
`

const CheckboxContainer = styled('div')`
  position: relative;
  padding-left: 1.5rem;
`

const CheckboxLabel = styled(Label)`
  position: relative;
  cursor: pointer;
  font-weight: inherit;
  font-size: 15px;
  line-height: 1.5em;
  margin-top: 0px;
  margin-bottom: 0px;
  color: ${props => (props.isActive ? '#AAAAAA' : 'inherit')};
  right: -20px;
  :before,
  :after {
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    left: -30px;
    position: absolute;
    top: 3px;
    transition: box-shadow 0.15s ease-in-out;
  }

  :before {
    pointer-events: none;
    user-select: none;
    border: 1.5px solid ${colors.brandBright};
    border-radius: 10px;
  }

  :after {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    border-radius: 10px;
  }
`

const Checkbox = styled('input')`
  display: inline-block;
  margin-right: 15px;
  opacity: 0;
  position: absolute;
  z-index: -1;
  cursor: pointer;

  &:focus ~ ${CheckboxLabel}:before {
    outline: 0;
    outline-offset: 0px;
  }

  &:active ~ ${CheckboxLabel}:before {
    color: ${colors.brand};
    background-color: ${colors.brandBright};
  }

  &:checked ~ ${CheckboxLabel}:after {
    background-color: ${colors.brandBright};
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  }
`

export default function SettingsTab(props) {
  const { profile } = props

  const {
    facebook,
    stripe_user_id,
    phoneVerified,
    socialVerified,
    newsletterSubscribed,
  } = profile

  const [isSubscribed, setSubscribed] = useState(newsletterSubscribed)

  const onToggleSubscription = async value => {
    try {
      await DatabaseService.updateSubscribe(value)
      setSubscribed(value)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="dashboard-settings-container">
      <h3>Payment</h3>

      <CheckRow>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            name="stripe_verified"
            checked={stripe_user_id}
            disabled={true}
          />
          <CheckboxLabel isActive={stripe_user_id}>
            Stripe account {stripe_user_id ? '' : 'not'} activated
          </CheckboxLabel>
        </CheckboxContainer>
        {!stripe_user_id ? (
          <Link to="/onboarding">
            <ActivateBtn>activate</ActivateBtn>
          </Link>
        ) : (
          <div />
        )}
      </CheckRow>

      <h3 style={{ marginTop: 40 }}>Verification</h3>
      <CheckRow>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            name="phone_verified"
            checked={phoneVerified}
            disabled={true}
          />
          <CheckboxLabel isActive={phoneVerified}>
            Phone number {phoneVerified ? '' : 'not'} verified
          </CheckboxLabel>
        </CheckboxContainer>
        {!phoneVerified ? (
          <div
            onClick={() =>
              navigate('/users/verify_phone', {
                state: { goAfter: '/dashboard/settings' },
              })
            }
          >
            <ActivateBtn>verify</ActivateBtn>
          </div>
        ) : (
          <div />
        )}
      </CheckRow>

      <CheckRow>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            name="social_verified"
            checked={socialVerified}
            disabled={true}
          />
          <CheckboxLabel isActive={socialVerified}>
            Social proof{' '}
            {socialVerified ? '' : facebook != null ? 'is being' : 'not'}{' '}
            verified
          </CheckboxLabel>
        </CheckboxContainer>
        {!socialVerified && facebook == null ? (
          <Link to="/users/verify_social">
            <ActivateBtn>verify</ActivateBtn>
          </Link>
        ) : (
          <div />
        )}
      </CheckRow>

      <h3 style={{ marginTop: 40 }}>Notifications</h3>
      <CheckRow>
        <CheckboxContainer>
          <Checkbox
            type="checkbox"
            name="subscribe"
            checked={isSubscribed}
            onChange={e => onToggleSubscription(e.target.checked)}
            id="checkbox-newsletter"
          />
          <CheckboxLabel htmlFor="checkbox-newsletter" isActive={isSubscribed}>
            Email me Mellow updates
          </CheckboxLabel>
        </CheckboxContainer>
      </CheckRow>
    </div>
  )
}

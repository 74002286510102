import React from 'react'
import Link from 'gatsby-link'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { navigate } from 'gatsby'

import './ItemEdit.scss'
import Uploader from 'components/shared/Forms/Uploader'
import { button, input, select, spacing } from 'utils/styles'
import DatabaseService from 'services/DatabaseService'

const DeleteBtn = styled('a')`
  ${button.default};
  ${button.big};
`
const SubmitBtn = styled('a')`
  ${button.purple};
  ${button.big};
`
const inputStyles = css`
  ${input.default};
  margin-top: ${spacing.sm}px;
  width: 100%;
  :hover {
    ${input.hover};
  }
  :focus {
    ${input.focus};
  }
  max-width: 80%;
`

const CategorySelect = styled('select')`
  ${inputStyles};
  ${select.default};
  margin: 0;
  max-width: 100%;
  padding: 10px 12px;

  @media (min-width: 650px) {
    ${select.small};
  }
`

export default class ItemsTabBase extends React.Component {
  constructor() {
    super()
    this.state = {
      name: '',
      zipcode: '',
      id: '',
      price_d: '',
      type: '',
      description: '',
      img: '',
      pictures: [],
      intialUploadCare: [],
      imagesLoaded: false,
      saved: true,
      saving: false,
    }
  }

  onSubmit = async data => {
    try {
      await DatabaseService.updateItem(data)
    } catch (error) {
      return error
    }
  }

  onDeleteItem = async id => {
    try {
      await DatabaseService.deleteItem(id)
    } catch (error) {
      return error
    }
  }

  componentDidMount() {
    if (this.props.location.state) {
      let {
        name,
        id,
        zipcode,
        price_d,
        type,
        description,
        pictures,
      } = this.props.location.state.item
      this.setState({
        id,
        name,
        zipcode,
        price_d,
        type,
        description,
        pictures,
      })
      this.setInitialUploadCare(pictures)
    } else {
      navigate('/dashboard/items/')
    }
  }

  uploadImg = info => {
    let splitUrl = info.cdnUrl.split('~')
    let imgCount = parseInt(splitUrl[splitUrl.length - 1].replace('/', ''))
    let pictures = []
    for (var i = 0; i < imgCount; i++) {
      let imgUrl = info.cdnUrl + 'nth/' + i + '/'
      pictures.push(imgUrl)
    }
    this.setState({ pictures: pictures, saved: false })
  }

  _submit = () => {
    this.setState({ saving: true })
    let data = {}
    data.id = this.state.id
    data.name = this.state.name
    data.pictures = this.state.pictures
    data.zipcode = this.state.zipcode
    data.price_d = this.state.price_d
    data.type = this.state.type
    data.description = this.state.description
    data.price_d = this.state.price_d
    data.phone = this.state.user_phone
    ;(async () => {
      const error = await this.onSubmit(data)
      if (error) {
        this.setState({ saving: false, error })
      }
      this.setState({ saving: false, saved: true })
    })()
  }

  _delete = () => {
    this.setState({ deleting: true })
    let id = this.state.id
    ;(async () => {
      const error = await this.onDeleteItem(id)
      if (error) {
        this.setState(error)
      }
      navigate('/dashboard/items')
    })()
  }

  onChange = event => {
    this.setState({ saved: false, [event.target.name]: event.target.value })
  }

  setInitialUploadCare = pictures => {
    let intialUploadCare = []
    pictures.map(picture => {
      if (picture.includes('ucarecdn')) {
        let refID = picture
          .split('.com/')
          .pop()
          .split('/')[0]
        intialUploadCare.push(refID)
      }
    })
    this.setState({ intialUploadCare, imagesLoaded: true })
  }

  render() {
    const {
      name,
      zipcode,
      price_d,
      type,
      description,
      pictures,
      saving,
      saved,
      intialUploadCare,
      imagesLoaded,
    } = this.state

    return (
      <div className="dashboard-tab">
        <div className="dashboard-tab dashboard-tab-wrapper">
          <div className="dashboard-home-container">
            <div className="dashboard-items-edit-center">
              <p className="dashboard-back-button">
                <Link to="/dashboard/items">Back to my items</Link>
              </p>

              <div className="dashboard-items-edit-wrapper">
                <form
                  method="POST"
                  role="edit-form"
                  className="edit-form"
                  id="edit-form"
                  onSubmit={this.onSubmit}
                >
                  <div className="dashboard-items-flex-container">
                    <div className="dashboard-items-half-container">
                      <fieldset className="fieldset fieldset-with-label">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          maxLength="250"
                          value={name}
                          onChange={this.onChange}
                        />
                      </fieldset>
                      <div>
                        <fieldset className="fieldset fieldset-with-label item-price">
                          <label htmlFor="type">Category</label>
                          <CategorySelect
                            name="type"
                            value={type}
                            onChange={this.onChange}
                          >
                            <option value="" disabled="">
                              {' '}
                              Select category
                            </option>
                            <option value="electric-scoot">
                              Electric Scooter
                            </option>
                            <option value="bike">Bike</option>
                            <option value="bike-mountain">Mountain Bike</option>
                            <option value="bike-road">Road Bike</option>
                            <option value="bike-hybrid">Hybrid Bike</option>
                            <option value="other">Other</option>
                          </CategorySelect>
                        </fieldset>
                      </div>
                    </div>
                    <div className="dashboard-items-half-container">
                      <fieldset className="fieldset fieldset-with-label item-price">
                        <label htmlFor="price_d">Price (Daily $)</label>
                        <input
                          name="price_d"
                          id="price_d"
                          type="number"
                          value={price_d}
                          onChange={this.onChange}
                        />
                      </fieldset>
                      <fieldset className="fieldset fieldset-with-label">
                        <label htmlFor="zipcode">Zipcode</label>
                        <input
                          type="text"
                          name="zipcode"
                          id="zipcode"
                          maxLength="250"
                          value={zipcode}
                          onChange={this.onChange}
                        />
                      </fieldset>
                    </div>
                  </div>
                  <div>
                    <div className="descriptionContainer">
                      <fieldset className="fieldset fieldset-with-label">
                        <label htmlFor="description">Description</label>
                        <textarea
                          className="text-input"
                          name="description"
                          type="text"
                          maxLength="1600"
                          rows="6"
                          value={description}
                          onChange={this.onChange}
                        />
                      </fieldset>
                    </div>
                  </div>
                </form>
                <div className="dashboard-items-img-edit">
                  <div className="uploadcare-container">
                    {pictures &&
                      imagesLoaded &&
                      pictures.map(picture => (
                        <img
                          key={picture}
                          src={picture}
                          height="100"
                          width="100"
                          className="item-img"
                        />
                      ))}
                    {imagesLoaded && (
                      <Uploader
                        id="file"
                        name="file"
                        value={intialUploadCare}
                        multiple={true}
                        onUploadComplete={this.uploadImg}
                      />
                    )}
                  </div>
                </div>
                <div className="bottom-btn-container">
                  <DeleteBtn onClick={() => this._delete()}>Delete</DeleteBtn>
                  <div>
                    <SubmitBtn disabled={saved} onClick={() => this._submit()}>
                      {saving ? 'Saving...' : saved ? 'Saved' : 'Save'}
                    </SubmitBtn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

import React, { useState } from 'react'
import { Match } from '@reach/router'

import useAsyncEffect from 'utils/hooks/useAsyncEffect'
import DatabaseService from 'services/DatabaseService'
import ItemRentalList from './ItemRentalList'

import styles from './RentalsTab.module.scss'

export default function RentalsTab(props) {
  const [history, setHistory] = useState(null)
  const [selected, setSelected] = useState(0)
  useAsyncEffect(async () => {
    const history = await Promise.all([
      DatabaseService.getCurrentUserRentalHistory(),
      DatabaseService.getCurrentUserLoanerHistory(),
    ])
    setHistory(
      history.map(qSnap => {
        return qSnap.docs.map(docSnap => {
          const doc = { id: docSnap.id, ...docSnap.data() }
          if (doc.item) {
            doc.item_id = doc.item.id
          }
          // convert the dates
          doc.start_date &&
            doc.start_date.toDate &&
            (doc.start_date = doc.start_date.toDate())
          doc.end_date &&
            doc.end_date.toDate &&
            (doc.end_date = doc.end_date.toDate())
          return doc
        })
      })
    )
  }, [])

  if (history === null) {
    return null
  }

  return (
    <>
      <div className={styles.toggleButtonsContainer}>
        <button
          className={[
            styles.toggleButtonLeft,
            styles.toggleButton,
            selected === 0 ? styles.toggleButtonSelected : '',
          ].join(' ')}
          onClick={() => setSelected(0)}
        >
          Renting
        </button>
        <button
          className={[
            styles.toggleButtonRight,
            styles.toggleButton,
            selected === 1 ? styles.toggleButtonSelected : '',
          ].join(' ')}
          onClick={() => setSelected(1)}
        >
          Lending
        </button>
      </div>
      <ItemRentalList items={history[selected]} lending={selected === 1} />
    </>
  )
}

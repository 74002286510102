import React from 'react'
import styled from '@emotion/styled'
import Moment from 'moment'
import Link from 'gatsby-link'
import { navigate } from 'gatsby'

import { MessageBtn, AlertBtn, ChoiceBtn } from './ActionButtons.js'
import { Tag } from './Tags.js'
import { colors } from 'utils/styles'
import { getRoute } from 'utils'

const Item = styled('li')`
  display: flex;
  position: relative;
  border-bottom: 1px solid ${colors.brandLight};
  margin: 0;
  padding: 20px 10px;
  cursor: pointer;
  max-height: ${props => (props.expanded ? '400px' : '200px')};
  transition: max-height 0.3s ease;
  overflow: hidden;
  :last-child:not(:first-child) {
    border-bottom: 0;
  }
  :hover {
    background-color: ${colors.brandLighter};
  }
`

const ItemTitle = styled('div')`
  font-size: 16px;
  line-height: 1.5em;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  color: #111;
`

const MODAL_A = 'modal_a'

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export default class ItemRentalItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      alertClicked: false,
      item: props.item,
    }
  }

  _clickExpanded = () => {
    this.setState({ expanded: !this.state.expanded })
  }

  convertToMoney = numb => {
    return formatter.format(numb / 100)
  }

  _clickAlert = e => {
    e.stopPropagation()
    if (!this.state.alertClicked) {
      this.setState({ alertClicked: true })
    } else {
      window.open(
        `mailto:team@borrowmellow.com?Subject=Rental%20Issue%20ID:%20#${
          this.state.item.id
        }`
      )
    }
  }

  _clickMessage = e => {
    e.stopPropagation()
    let id = ''
    if (this.props.lending) {
      id = this.props.item.renter.id
    } else {
      id = this.props.item.lender.id
    }
    navigate(`/message?recipient=${id}`)
  }

  render() {
    Moment.locale('en')
    const { item, lending } = this.props
    const { alertClicked, expanded } = this.state
    // TODO: deprecate this ackwards compatability hack
    if (!item.price) {
      item.price = {
        fee: (item.total_price - item.total_price_nofee) * 100,
        total: item.total_price * 100,
      }
    }

    const price_d = this.convertToMoney(item.item_price)
    const service_fee = this.convertToMoney(item.price.fee)
    const total_price = this.convertToMoney(item.price.total)
    return (
      <Item key={item.id} onClick={this._clickExpanded} expanded={expanded}>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
            <Link to={`/item/${item.item_id}/`}>
              <img
                src={item.item_img}
                height="100"
                width="100"
                className="rental-lst-img"
              />
            </Link>
            <div className="rental-list-column">
              <ItemTitle>{item.item_name}</ItemTitle>
              <p className="hint-text">
                {Moment(item.start_date).format('MMM Do')} -{' '}
                {Moment(item.end_date).format('MMM Do')}
              </p>
              {expanded && (
                <>
                  <div className="hint-text">
                    {lending ? 'Renter' : 'Lender'}:
                    <Link
                      to={getRoute('PROFILE', {
                        id: lending ? item.renter.id : item.lender.id,
                      })}
                    >
                      {lending ? item.renter_name : item.lender_name}
                    </Link>
                  </div>
                  <p className="hint-text">Price: {price_d} /day</p>
                  <p className="hint-text">Service Fee: {service_fee}</p>
                  <p className="hint-text">Total Price: {total_price}</p>
                  <p className="hint-text">Number of days: {item.num_days}</p>
                  <p className="hint-text">Rental ID: #{item.id} </p>
                </>
              )}
              <Tag item={item} />
            </div>
            <div className="rental-list-row">
              <MessageBtn
                item={item}
                lending={lending}
                onClick={this._clickMessage}
              />
              <ChoiceBtn
                item={item}
                lending={lending}
                onClick={() => navigate(`/rental-confirmation?id=${item.id}`)}
              />
            </div>
            <div className="alert-button">
              <AlertBtn
                item={item}
                lending={lending}
                onClick={this._clickAlert}
                alertClicked={alertClicked}
              />
            </div>
            <div />
          </div>
          <div className="view-more">view {expanded ? 'less' : 'more'}</div>
        </div>
      </Item>
    )
  }
}
